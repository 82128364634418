.primary {
  color: #454545;
}

.secondary {
  color: #d35e34;
}

a, .likeA {
  color: #454545;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

a:hover, .likeA:hover {
  color: #b69386;
}

#top-menu a {
  font-size: 18px;
}

#top-submenu a {
  font-size: 14px;
}

ul#top-submenu {
  margin: 0;
  text-align: right;
}

a.logout {
  color: rgb(20, 11, 2);
  text-decoration: none;
}

a.logout:hover {
  color: rgb(255, 174, 0);
}

.disabled {
  color: rgb(196, 196, 196);
}

header {
  width: 960px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 30px;
}

#header {
  background-color: rgb(236, 224, 199);
}

#subheader {
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-color: rgb(158, 144, 133);
  color: rgb(0, 0, 0);
  padding: 10px;
  width: 940px;
  height: 20px;
  margin: auto;
  font-size: 14px;
}

#top-menu li, #top-submenu li {
  display: inline-block;
  margin-right: 10px;
  padding-right: 10px;
}

#top-menu a, #top-submenu a {
  color: #454545;
  text-decoration: none;
  text-shadow: 1px 1px hsl(0, 0%, 88%);
  padding: 2px 5px;
}

#top-submenu a {
  color: white;
  text-shadow: none
}

#top-menu a:hover {
  background-color: wheat;
  text-shadow: none;
}

#top-submenu a:hover {
  background-color: #b9aa8e;
  text-shadow: none;
}

header ul#top-menu {
  float: right;
  position: relative;
  top: 4px;
}

header .logo {
  top: 5px;
  left: 5px;
  position: relative;
  width: 60px;
  display: block;
  float: left;
}

#content {
  display: block;
  width: 960px;
  margin: auto;
}

#login, #register {
  text-align: center;
}

.biglogo {
  width: 300px;
}

#login form input {
  padding: 10px;
}

button {
  padding: 10px 20px;
  background: none;
  border: 1px solid #4a4847;
  background-color: #454545;
  color: rgb(207, 207, 207);
  cursor: pointer;
}

button:hover, button.faded:hover {
  border: 1px solid #110d0c;
  background-color: #8c8785;
  color: white;
}

button.faded {
  border: 1px solid #494847;
  background-color: #74716f;
  color: rgb(207, 207, 207);
}

.error {
  font-size: 12px;
  color: red;
}

.calc-row {
  display: grid;
  grid-template-columns: 20px 1fr 1fr 1fr 1fr 160px 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  margin: 5px 0;
  border-bottom: 1px solid rgb(207, 207, 207);
}

.calc-row.header {
  font-weight: bold;
  border-bottom-width: 3px;
}

.calc-row:hover {
  background-color: rgb(233, 233, 233);
}

.calc-row.header:hover {
  background-color: transparent;
}

.calc-row span {
  text-align: right;
}

#margin-input {
  padding: 10px;
  background-color: gray;
  color: white;
}

#margin-input input {
  width: 50px;
  text-align: right;
  padding: 5px;
}

.agreement-row {
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 10px;
}

.agreement-row:hover {
  background-color: rgb(233, 233, 233);
}

.agreement-row.header:hover {
  background-color: transparent;
}

.agreement-row.header {
  font-weight: bold;
}

#myaccount {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
}

#myaccount input {
  margin-bottom: 10px;
}

#data-status {
  background-color: #e9e9e9;
  padding: 10px;
  text-align: right;
}

#data-status.saving {
  background-color: #fddede;
}

#data-status.saved {
  background-color: #d1ecd9;
}

.ui.radio.checkbox {
  margin-bottom: 10px;
}

#homepage {
  text-align: center;
  padding-top: 20px;
  min-height: 400px;
  color: #363433;
}

#homepage .background {
  opacity: 0.5;
  background-image: url('images/office.jpg');
  background-size: cover;
  width: 960px;
  height: 300px;
  position: absolute;
  opacity: 0.8;
  background-size: cover;
  z-index: -10;
}

#myproducts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px 20px;
}

.product-item {
  background-color: #f9f6ee;
  display: block;
  padding: 10px;
  border-radius: 4px;
}

.product-item .editing-fields {
  display: grid;
  grid-template-columns: 115px 1fr;
  grid-gap: 5px 10px;
}

.paper-inactive, .paper-selected {
  background-color: gray;
  color: #adadad;
  padding: 5px;
  margin: 5px;
  font-size: 9px;
}

.paper-selected {
  background-color: #807b75;
  color: #ffffff;
  font-weight: bold;
}

.product-item .title {
  font-size: 18px;
  border-bottom: 1px solid #ececec;
  text-transform: capitalize;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.product-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.image-holder {
  width: 145px;
  height: 145px;
}

.image-holder img {
  width: 92%;
  border: 2px solid gray;
  padding: 4%;
  background-color: white;
}

.product-info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  text-align: right;
}

.product-info .margin {
  font-size: 24px;
  text-align: right;
}

.product-info .status {
  color: gray;
}

.papers-selection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;
  text-align: center;
}

#productdata-status {
  color: #8b8b8b;
  text-align: left;
}

#productdata-status.saving {
  color: #ab9e8a;
}

#productdata-status.saved {
  color: #b8deb6;
}

.editing-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

footer {
  width: 960px;
  color: rgb(216, 216, 216);
  margin: 20px auto;
  padding: 10px;
  text-align: right;
}

#print-materials>div {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

#print-materials img {
  width: 300px;
}